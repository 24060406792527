<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link to="/"
                       class="d-flex align-center">
            <v-img :src="appLogo"
                   max-height="30px"
                   max-width="30px"
                   alt="logo"
                   contain
                   class="me-3 "></v-img>

            <h2 class="text-2xl font-weight-semibold">
              Forgot Password
            </h2>
          </router-link>
        </v-card-title>

        <v-card-text v-if="success">
          <v-alert text
                   color="success">
            <small class="d-block mb-1">
              Password reset requested, please check your email for the next step.
            </small>
          </v-alert>
        </v-card-text>

        <v-card-text v-if="failed">
          <v-alert text
                   color="error">
            <small class="d-block mb-1">
              Something has gone wrong, please try again later.
            </small>
          </v-alert>
        </v-card-text>

        <v-card-text v-if="!success">
          <p class="mb-2">
            Enter your email and we'll send you instructions to reset your password.
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text v-if="!success">
          <v-form @submit.prevent="resetPassword">
            <v-text-field v-model="email"
                          outlined
                          label="Email"
                          placeholder="john@example.com"
                          hide-details
                          class="mb-4"></v-text-field>

            <v-btn block
                   type="submit"
                   color="primary">
              Send reset link
            </v-btn>
          </v-form>
        </v-card-text>

        <v-card-actions class="d-flex justify-center align-center">
          <router-link :to="{name:'auth-login'}"
                       class="d-flex align-center text-sm">
            <v-icon size="24"
                    color="primary">
              {{ icons.mdiChevronLeft }}
            </v-icon>
            <span>Back to login</span>
          </router-link>
        </v-card-actions>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img class="auth-mask-bg"
         height="190"
         :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)" />
  </div>
</template>

<script>
  import { mdiChevronLeft } from '@mdi/js'
  import { ref, getCurrentInstance } from '@vue/composition-api'
  import themeConfig from '@themeConfig'

  export default {
    setup() {
      const vm = getCurrentInstance().proxy
      const isPasswordVisible = ref(false)
      const email = ref('')
      let success = ref(false)
      let failed = ref(false)

      const resetPassword = () => {

        const isFormValid = vm.email.length > 0

        if (!isFormValid) return
        vm.$http
          .post(vm.$store.state.apiUrl + '/auth/forgot-password', { email: email.value })
          .then(response => {
            if (response) {
              vm.success = true
            }
            else {
              vm.failed = true
            }
          })
          .catch(error => {
            vm.failed = true
          })
      }

      return {
        isPasswordVisible,
        email,
        resetPassword,
        failed,
        success,

        // themeConfig
        appName: themeConfig.app.name,
        appLogo: themeConfig.app.logo,

        icons: {
          mdiChevronLeft,
        },
      }
    },
  }
</script>

<style lang="scss">
  @import '@core/preset/preset/pages/auth.scss';
</style>
